import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EventChaordicService } from 'src/app/services/event.service';
import {
  eventSearchSuccess,
  getShowcasesChaordic,
  sendShowcasesClick,
  sendShowcasesImpression,
  setEventClick,
  setEventSearch,
  setEventView,
  setShowcasesChaordic,
  successShowcasesClick,
  successShowcasesImpression
} from '../actions/event.actions';
import { AffiliateEvents } from 'src/app/services/home/affiliate.model';

@Injectable()
export class EventEffects {

  constructor(private actions$: Actions, private eventChaordic: EventChaordicService) { }

  eventChaordicEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEventSearch),
      mergeMap((props) => this.eventChaordic.sendEvent(props.data).pipe(
        map(() => eventSearchSuccess()),
        catchError((error) => of(error)),
      )),
      catchError((error) => throwError(() => error)),
    ),
  );

  getShowCasesChaordicEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getShowcasesChaordic),
      switchMap(() => this.eventChaordic.getShowCaseChaordic().pipe(
        map((resp) => setShowcasesChaordic({ showcases: resp })),
        catchError((error) => throwError(() => error)),
      )),
      catchError((err) => throwError(() => err))
    ),
  );

  sendShowcasesClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendShowcasesClick),
      mergeMap((props) => this.eventChaordic.sendShowCaseClick(props.trackingId, props.panvelCode).pipe(
        map(() => successShowcasesClick()),
        catchError((error) => throwError(() => error)),
      )),
      catchError((error) => throwError(() => error)),
    )
  );

  sendShocaseImpression$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendShowcasesImpression),
      mergeMap((props) => this.eventChaordic.sendShowCaseView(props.impressionUrl).pipe(
        map(() => successShowcasesImpression()),
        catchError((error) => throwError(() => error)),
      )),
      catchError((error) => throwError(() => error)),
    )
  );

  eventViewChaordicEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEventView),
      mergeMap(() => this.eventChaordic.sendEventView().pipe(
        map(() => eventSearchSuccess()),
        catchError((error) => of(error)),
      )),
      catchError((error) => throwError(() => error)),
    ),
  );

  eventAffiliateEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEventClick),
      mergeMap(({ affiliateEvents }) =>
        this.eventChaordic.postAffiliateEvent(affiliateEvents).pipe(
          map(() => {
            this.verifyHref(affiliateEvents);
            return eventSearchSuccess();
          }),
          catchError((error) => {
            this.verifyHref(affiliateEvents);
            return of(error)
          }),
        )),
      catchError((error) => throwError(() => error)),
    ),
  );

  private verifyHref(request: AffiliateEvents): void {
    if (request?.link) {
      window.location.href = request.link
    }
  }
}
