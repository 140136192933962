import { createReducer, on } from '@ngrx/store';
import { ShowcaseChaordic } from 'src/app/services/home/showcase.chaordic-model';
import { setShowcasesChaordic } from '../actions/event.actions';

export const chaordicFeatureKey = 'chaordic';

export interface ChaordicState {
  showcases: ShowcaseChaordic[]
}

export const initialState: ChaordicState = {
  showcases: null,
};

export const reducer = createReducer<ChaordicState>(
  initialState,
  on(setShowcasesChaordic, (state, action): ChaordicState => ({
    ...state,
    showcases: action.showcases
  }))
);
