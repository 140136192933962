import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { CookieInterface } from "./cookie.interface";

@Injectable({ providedIn: 'root'})
export class CookiePanvel implements CookieInterface {
  constructor(
    private cookie: CookieService,
    ) {}

  logout(): void {
    this.removeForName('eToken');
    this.removeForName('refresh_token');
    this.removeForName('customer_persona');
    this.removeForName(environment.cookieCartName);

  }

  add(key: string, value: string, expires?: Date): void {
    this.cookie.set(key, value, {
      domain: environment.domain,
      path: '/',
      expires
    })
  }

  removeForName(value: string): void {
    this.cookie.delete(value, '/', environment.domain)
    this.removeCookie(value);
  }

  removeAll(): void {
    this.cookie.deleteAll('/', environment.domain);
  }

  get(key: string): string {
    return this.cookie.get(key);
  }

  getAll(): any {
    return this.cookie.getAll();
  }


  private removeCookie(cookieName: string): void {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }

}





