import {createAction, props} from '@ngrx/store';
import { Showcase } from 'src/app/services/home/showcase-model';

export const loadShowCase = createAction(
  '[ShowCase] Load ShowCase',
  props<{ convenance?: string, uf?: string }>()
);

export const loadRecomendationCustomer = createAction(
  '[ShowCase] Load Recommendation Customer',
  props<{ convenance?: string, uf?: string }>()
);

export const loadRecomendationCustomerSuccess = createAction(
  '[ShowCase] Load ShowCase Customer Success',
  props<{ data: Showcase[]}>()
);

export const loadRecomendationCustom = createAction(
  '[ShowCase] Load Recommendation Custom',
  props<{ convenance?: string, uf?: string }>()
);

export const loadRecomendationCustomSuccess = createAction(
  '[ShowCase] Load ShowCase Custom Success',
  props<{ data: Showcase[]}>()
);

export const loadShowCaseSuccess = createAction(
  '[ShowCase] Load ShowCase Success',
  props<{ data: Showcase[]}>()
);

export const loadShowCaseFailure = createAction(
  '[ShowCase] Load ShowCase Failure',
  props<{ error: string}>()
);
