import { HttpParams } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ContentBanner } from 'src/app/services/banners/models/banner.model';

export const getBanners = createAction(
  '[GET BANNERS] Get banners',
  props<{ params: HttpParams }>()
);
export const updateBanners = createAction(
  '[UPDATE BANNERS] update banners',
  props<{ banners: ContentBanner[] }>()
);
