import {createAction, props} from '@ngrx/store';
import {IAddItemToCart} from '../../shared/interfaces/IAddItemToCart';

export const addItemToCart = createAction(
  '[AddItem] Change quantities',
  props<{ item: IAddItemToCart }>()
);

export const addItemToCartFailure = createAction(
  '[Add Item to Cart Failure] Fail',
  props<{ error: string[] }>()
);
