import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as staticsReducer from '../reducers/statics.reducer';

export const getProductFeatureState = createFeatureSelector<staticsReducer.StaticsState>(
  staticsReducer.StaticsFeatureKey
);

export const getStatics = createSelector(
  getProductFeatureState,
  state => state?.statics
);