import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Refresh } from './model/request/login-response.model';


@Injectable({
  providedIn: 'root'
})
export class RefreshApi {
    constructor(private http: HttpClient) {}

    refreshToken(token: string): Observable<Refresh> {
        return this.http.post<Refresh>(environment.baseHref + 'api/v1/oauth/refresh', { refreshToken: token })
    }
}
