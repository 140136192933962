<section *ngIf="globalTheme">
  <div class="header-nav">
    <div class="header-light">
      <lib-panvel-header
        [static]="statics$ | async"
        [cart]="cart$ | async"
        [config]="config"
        (deleteShoppingCart)="shoppingCartDelete()"
        (newCart)="sendCart($event)"
        (cepEvent)="cepEvent($event)"
        (notificationEvent)="notificationEvent($event)"
        (repurchaseEvent)="sendRepurchaseEvent($event)"
        (repurchaseEventMobile)="sendRepurchaseEvent($event)"
      ></lib-panvel-header>
    </div>
  </div>

  <div class="home">
    <app-statics-skeleton *ngIf="((banners$ | async) === null && bannerABTest) || (statics$ | async) === null">      
    </app-statics-skeleton>

    <app-main-banner 
      *ngIf="banners$ | async as banners"
      [banners]="banners"
      [persona]="persona"    
    ></app-main-banner>
    
    <app-home-content-statics
      *ngIf="statics$ | async as statics"
      [statics]="statics"
      [showStaticBanner]="!bannerABTest"
    ></app-home-content-statics>

    <app-home-content-customer [theme]="globalTheme" *appShowIfScroll=".5"></app-home-content-customer>
    <app-home-content-custom [theme]="globalTheme" *appShowIfScroll=".5"></app-home-content-custom>
    <app-dynamic-loading [theme]="globalTheme"></app-dynamic-loading>
    <app-cookie></app-cookie>
  </div>

  <div class="footer">
    <lib-p-footer [theme]="globalTheme.toUpperCase()" *appShowIfScroll></lib-p-footer>
  </div>
</section>