import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewComponentService } from 'panvel-utils-lib';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { ContentBanner } from 'src/app/services/banners/models/banner.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() banners: ContentBanner[];
  @Input() theme: string = environment.theme;
  @Input() persona: string;

  @ViewChild("mainBanner") mainBanner!: ElementRef;
  private bannersSent = new Set<number>();

  // CONTROLS
  imageIndex = 0;
  bannerLength: number;
  bannerArrayIndex: number[];
  private interval$: Observable<number> = interval(7000);
  private destroyInterval$ = new Subject();
  private destroy$ = new Subject();

  constructor(private readonly viewService: ViewComponentService) { }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  ngOnInit(): void {
    if (this.banners) {
      this.bannerLength = this.banners.length - 1;
      this.bannerArrayIndex = Array.from({ length: this.bannerLength + 1 }, (_, index) => index);
    }
  }

  ngAfterViewInit(): void {
    this.viewService
      .build({ component: this.mainBanner, threshold: 0.8, closeOnceRequest: false, checkHidden: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((isShowing) => {
        if (isShowing) {
          this.sendViewBanner();
          this.startInterval();
        } else this.stopInterval();
      })
  }

  // CONTROL
  startInterval() {
    this.interval$
      .pipe(takeUntil(this.destroyInterval$))
      .subscribe(() => this.nextBanner())
  }

  stopInterval() {
    this.destroyInterval$.next(false)
    this.destroyInterval$.complete();
  }

  nextBanner() {
    if (this.imageIndex < this.bannerLength) this.imageIndex += 1;
    else this.imageIndex = 0;
    this.sendViewBanner();
  }

  prevBanner() {
    if (this.imageIndex > 0) this.imageIndex -= 1;
    else this.imageIndex = this.bannerLength;
    this.sendViewBanner();
  }

  selectBanner(index: number) {
    this.imageIndex = index;
  }

  // EVENTS
  sendViewBanner() {
    const currentIndex = this.imageIndex;
    if (!this.bannersSent.has(currentIndex)) {
      this.bannersSent.add(currentIndex);
      window?.dataLayer?.push(
        this.eventBanner(this.banners[currentIndex], 'view_promotion')
      );
    }
  }

  sendSelectBanner() {
    const currentIndex = this.imageIndex;
    window?.dataLayer?.push(
      this.eventBanner(this.banners[currentIndex], 'select_promotion')
    );
    window?.dataLayer?.push(this.bannerHomeGTM(this.banners[currentIndex]))
  }

  eventBanner(banner: ContentBanner, event: 'select_promotion' | 'view_promotion') {
    return {
      event,
      ecommerce: {
        creative_name: banner?.title ?? '',
        creative_slot: banner?.title ?? '',
        promotion_id: banner?.id ?? '',
        location_id: banner?.location ?? '',
      }
    };
  }

  bannerHomeGTM(banner: ContentBanner): any {
    return {
      event: 'bannerHome',
      bannerName: banner?.title ?? '',
      bannerCampaign: banner?.title ?? '',
      persona: this.persona
    };
  }
}