import { Component } from '@angular/core';
import {InfoBarItemBaseDirective} from '../info-bar-item-base.directive';

@Component({
  selector: 'app-info-bar-item',
  templateUrl: './info-bar-item.component.html',
  styleUrls: ['./info-bar-item.component.scss']
})
export class InfoBarItemComponent extends InfoBarItemBaseDirective {

  constructor() {
    super();
  }
}

