import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WheelRequest } from "../home/wheel-request.model";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class WheelServiceCadaster {
  constructor(private http: HttpClient) {}

  public send(body: WheelRequest): Observable<void> {
    return this.http.post<void>(
      environment.baseHref + "api/v2/customer/leads",
      body
    );
  }
}
