import { createReducer, on } from '@ngrx/store';
import * as ShowCaseAction from '../actions/show-case.actions';
import { Showcase } from 'src/app/services/home/showcase-model';

export const ShowCaseFeatureKey = 'showCase';
export interface ShowCaseState {
  showcases?: Showcase[];
  showcasesCustomer?: Showcase[];
  showcasesCustom?: Showcase[];
  error?: string;
}

export const initialState: ShowCaseState = {
  showcases: null,
  showcasesCustomer: null,
  showcasesCustom: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(ShowCaseAction.loadShowCaseSuccess, (state, action): ShowCaseState =>
  ({
    ...state,
    showcases: action.data,
    error: null,
  })),
  on(ShowCaseAction.loadShowCaseFailure, (state, action): ShowCaseState =>
  ({
    ...state,
    showcases: null,
    error: action.error,
  })),
  on(ShowCaseAction.loadRecomendationCustomerSuccess, (state, action): ShowCaseState =>
    ({
      ...state,
      showcasesCustomer: action.data,
    })),
  on(ShowCaseAction.loadRecomendationCustomSuccess, (state, action): ShowCaseState =>
    ({
      ...state,
      showcasesCustom: action.data,
    })),
);
