import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { Response } from "express";
import { CookieService } from "ngx-cookie";
import {
  IConfigHeader,
  ITheme,
} from "p-header-lib/lib/services/header-core.service";
import { Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import * as CartAction from "../../store/actions/cart.actions";
import { setInitCart } from "../../store/actions/cart.actions";
import * as tagManagerActions from "../../store/actions/tagmanager.actions";
import { getCart } from "../../store/selectors/item.selectors";
import { AppState } from "../../store/state/app.state";
import { StaticsResponse } from "src/app/services/home/statics.model";
import { getStatics } from "src/app/store/selectors/statics.selectors";
import { Cart } from "panvel-utils-lib/lib/models/shopping-cart-response.model";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  private response: Response;
  public showError = false;
  public homePath = environment.panvelHome;
  public cartPath = environment.panvelCart;
  public panvelLogin = environment.panvelLogin;
  public panvelDomain = environment.domain;
  public showSubMenu = true;
  public cart$: Observable<Cart>;
  public statics$: Observable<StaticsResponse>;
  public isBrowser: boolean = false;
  public globalTheme = environment.theme;
  public status: boolean;
  private subs?: Subscription;
  @Output() newCart = new EventEmitter<Cart>();

  public config: IConfigHeader = {
    theme: this.globalTheme as ITheme,
    loginPath: this.panvelLogin,
    cartPath: this.cartPath,
    domain: this.panvelDomain,
    miniCheckout: true,
    subMenu: true,
    subMenuClinic: false,
  };

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    public cookie: CookieService,
    @Optional() @Inject(RESPONSE) response: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.response = response;
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.cart$ = store.select(getCart);
    this.statics$ = store.select(getStatics);

    this.subs = this.route.data.subscribe((data) => {
      const cartSelected = data["item"];
      this.store.dispatch(setInitCart({ cart: cartSelected?.cart }));
    });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.response.status(404);
    }
  }

  sendCart($event): void {
    this.store.dispatch(CartAction.setInitCart({ cart: $event }));
  }

  setTGM(data): void {
    this.store.dispatch(
      tagManagerActions.pushBannerMenuTagManager({ banner: data })
    );
  }

  handleError() {
    this.showError = !this.showError;
  }

  public shoppingCartDelete(): void {
    this.cookie.put("/", environment.domain);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
