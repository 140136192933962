<div class="isDesktop">
  <div class="skeleton-container">
    <div class="skeleton-title">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '50px',
          width: '250px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="skeleton-content">
      <ngx-skeleton-loader
        *ngFor="let item of [].constructor(5); let i = index"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '300px',
          width: '217px',
          margin: '0 13px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="skeleton-container">
    <div class="skeleton-content">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '297px',
          width: '997px',
          margin: '0 13px',
          'justify-content': 'center'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div class="isMobile">
  <div class="skeleton-container story-mobile-carousel">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress"
      [theme]="{
        'background-color': backgroundColor,
        height: '350px',
        width: '90vw',
        margin: '0 13px',
        'justify-content': 'center'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="skeleton-container">
    <div class="skeleton-title">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '50px',
          width: '250px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="skeleton-content">
      <ngx-skeleton-loader
        *ngFor="let item of [].constructor(3); let i = index"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '300px',
          width: '190px',
          margin: '0 13px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="skeleton-container">
    <div class="skeleton-content">
      <ngx-skeleton-loader
        *ngFor="let item of [].constructor(3); let i = index"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '274px',
          width: '180px',
          margin: '0 13px',
          'border-radius': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="skeleton-container">
    <div class="skeleton-content">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': backgroundColor,
          height: '163px',
          width: '351px',
          margin: '0 13px',
          'justify-content': 'center'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
