import { APP_BASE_HREF, CommonModule, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PFooterModule } from 'p-footer';
import { PHeaderModule } from 'p-header-lib';
import { BannerModule, PUtilsLibModule, WheelModule, SwipeDirectiveModule } from "panvel-utils-lib";
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerDirective } from './component/banner-scroll/banner-scroll.directive';
import { CardBannerDirective } from './component/banner-scroll/card-banner-scroll.directive';
import { CookieComponent } from './component/cookie/cookie.component';
import { HealthCheckComponent } from './component/health-check/health-check.component';
import { InfoBarItemBaseDirective } from './component/info-bar-item/info-bar-item-base.directive';
import { InfoBarItemComponent } from './component/info-bar-item/info-bar-item-light/info-bar-item.component';
import { InfoBarDesktopComponent } from './component/info-bar/info-bar-desktop/info-bar-desktop.component';
import { InfoBarMobileComponent } from './component/info-bar/info-bar-mobile/info-bar-mobile.component';
import { MainBannerImageComponent } from './component/main-banner/main-banner-image/main-banner-image.component';
import { MainBannerComponent } from './component/main-banner/main-banner.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { ShippingBarComponent } from './component/shipping-bar/shipping-bar.component';
import { ShowcaseStoryMobileBaseDirective } from './component/showcase-story-mobile/showcase-story-mobile-base.directive';
import { ShowcaseStoryBaseDirective } from './component/showcase-story/showcase-story-base.directive';
import { DynamicLoadingComponent } from './home/dynamic-loading/dynamic-loading.component';
import { HomeContentCustomComponent } from './home/home-content-custom/home-content-custom.component';
import { HomeContentCustomerComponent } from './home/home-content-customer/home-content-customer.component';
import { HomeContentStaticsComponent } from './home/home-content-statics/home-content-statics.component';
import { HomeComponent } from './home/home.component';
import { SharedShowcaseModule } from './home/shared-showcase/shared-showcase.module';
import { ShowcaseSkeletonComponent } from './home/showcase-skeleton/showcase-skeleton.component';
import { StaticsSkeletonComponent } from './home/statics-skeleton/statics-skeleton.component';
import { AddShoppingCartInterceptor } from './interceptors/common/add-cart.interceptor';
import { AddHeadersInterceptor } from './interceptors/common/add-headers.interceptor';
import { ResponseInterceptor } from './interceptors/common/response.interceptor';
import { BrowserStateInterceptor } from './interceptors/spa/browser-state.interceptor';
import { FingerInterceptor } from './interceptors/spa/fingerprint.interceptor';
import { CartResolver } from './services/cart-resolver/cart-resolver.service';
import { BannersEffects } from './store/effects/banners.effects';
import { DataDogEffects } from './store/effects/data-dog.effects';
import { EventEffects } from './store/effects/event.effects';
import { ItemEffects } from './store/effects/item.effects';
import { ShowCaseEffects } from './store/effects/show-case.effects';
import { StaticsEffects } from './store/effects/statics.effects';
import { TagManagerEffects } from './store/effects/tagmanager.effects';
import { WheelEffect } from './store/effects/wheel.effects';
import { reducers } from './store/reducers/root.reducer';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HomeContentStaticsComponent,
    DynamicLoadingComponent,
    ShowcaseSkeletonComponent,
    StaticsSkeletonComponent,
    NotFoundComponent,
    InfoBarDesktopComponent,
    InfoBarItemComponent,
    InfoBarMobileComponent,
    ShippingBarComponent,
    HomeComponent,
    InfoBarItemBaseDirective,
    CookieComponent,
    ShowcaseStoryBaseDirective,
    ShowcaseStoryMobileBaseDirective,
    BannerDirective,
    CardBannerDirective,
    HealthCheckComponent,
    HomeContentCustomerComponent,
    HomeContentCustomComponent,
    MainBannerComponent,
    MainBannerImageComponent
  ],
  imports: [
    NgOptimizedImage,
    CommonModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    SharedShowcaseModule,
    PHeaderModule,
    PFooterModule,
    TransferHttpCacheModule,
    PUtilsLibModule.forRoot(environment),
    SwipeDirectiveModule,
    MatDialogModule,
    WheelModule,
    RecaptchaV3Module,
    NgxMaskModule.forRoot(),
    CookieModule.forRoot(),
    EffectsModule.forRoot([
      ItemEffects,
      TagManagerEffects,
      StaticsEffects,
      ShowCaseEffects,
      DataDogEffects,
      EventEffects,
      WheelEffect,
      BannersEffects
    ]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgxSkeletonLoaderModule.forRoot({
      animation: "pulse",
      loadingText: "This item is actually loading...",
    }),
    BrowserAnimationsModule,
    BannerModule,
  ],
  providers: [
    CartResolver,
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenRefreshInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddShoppingCartInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey
      } as RecaptchaSettings,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
