import {Component, OnInit} from '@angular/core';
import {ShowcaseStoryBaseDirective} from '../showcase-story-base.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-showcase-story-carousel',
  templateUrl: './showcase-story-carousel.component.html',
  styleUrls: ['./showcase-story-carousel.component.scss']
})
export class ShowcaseStoryCarouselComponent extends ShowcaseStoryBaseDirective implements OnInit {
  public theme = environment.theme;

  ngOnInit(): void {
    this.storys = this.showcaseStory.stories;
    this.storyList = this.storys;
  }
}
