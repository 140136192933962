import { pushUserData } from './../store/actions/tagmanager.actions';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component, EventEmitter, HostBinding,
  HostListener, Inject, OnDestroy, OnInit, Output, PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookiePanvel } from '../services/cookie/cookie.implementation';
import * as CartAction from '../store/actions/cart.actions';
import { getShowcasesChaordic, setEventView } from '../store/actions/event.actions';
import * as tagManagerActions from '../store/actions/tagmanager.actions';
import { IConfigHeader, ITheme } from 'p-header-lib/lib/services/header-core.service';
import { PriceChangeNotificationService, WheelService } from 'panvel-utils-lib';
import { NotificationEvent } from 'p-header-lib/lib/models/notitications.model';
import { RepurchaseEvent } from "p-header-lib/lib/models/repurchase-event.model";
import { Cart } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';
import { StaticsResponse } from '../services/home/statics.model';
import { getCart } from '../store/selectors/item.selectors';
import { getStatics } from '../store/selectors/statics.selectors';
import { AppState } from '../store/state/app.state';
import { sendWheelForm } from '../store/actions/wheel.actions';
import { WheelBuilder } from '../builders/wheel.builder';
import { ContentBanner } from '../services/home/content-banner-model';
import { getBannersHome } from '../store/selectors/banner.selectors';
import { getBanners } from '../store/actions/banner.actions';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() newCart = new EventEmitter<Cart>();
  public cart$: Observable<Cart>;
  public statics$: Observable<StaticsResponse>;
  public banners$: Observable<ContentBanner[]>;
  public panvelHome = environment.panvelHome;
  public panvelLogin = environment.panvelLogin;
  public panvelCart = environment.panvelCart;
  public panvelDomain = environment.domain;
  public globalTheme = environment.theme;
  public status: boolean;
  private eventsDispatched = false;
  private destroy$ = new Subject<void>();

  public persona: string;
  public bannerABTest: boolean;
  public config: IConfigHeader = {
    theme: environment.theme as ITheme,
    loginPath: this.panvelLogin,
    cartPath: environment.panvelCart,
    domain: environment.domain,
    miniCheckout: true,
    subMenu: true,
    subMenuClinic: false,
    notificationEventExternal: true,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private route: ActivatedRoute,
    private router: Router,
    private cookie: CookiePanvel,
    private _notificationService: PriceChangeNotificationService,
    private store: Store<AppState>,
    private wheelService: WheelService
  ) {
    this.cart$ = store.select(getCart);
    this.statics$ = store.select(getStatics);
    this.banners$ = store.select(getBannersHome);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.bannerABTest = !!this.cookie.get('main_banner_test');
  }

  ngAfterViewInit(): void {
    if (this.isSpa) {
      this.route.data.subscribe((data: { item: any }) => {
        const cart = data?.item?.cart as Cart;
        if (!cart?.customer) this.getWheel();
      })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (this.isSpa) {
      if (this.bannerABTest) this.store.dispatch(getBanners({ params: this.getBannersParams() }));
      this.route.data.subscribe((data: { item: any }) => {
        const cart = data.item.cart as Cart;
        this.store.dispatch(CartAction.setInitCart({ cart }));
        window.dataLayer?.push({ event: 'optimize.activate' });
      })

      this.cart$
        .pipe(
          takeUntil(this.destroy$),
          first((cart) => !!cart)
        )
        .subscribe((cart) => {
          if (cart?.customer) {
            this.store.dispatch(pushUserData({ customer: cart?.customer }))
            this._notificationService.cepEvent(cart, { checkCookie: true })
          } else {
            if (this.cookie.get("wheel")) {
              this._notificationService.notLogged({ checkCookie: true }, this.panvelLogin)
            }
          }
        })
    }
  }

  private getBannersParams(): HttpParams {
    this.persona = this.validatePersona(this.cookie.get("customer_persona"));
    return new HttpParams()
      .set('page', 'HOME')
      .set('location', 'MAIN')
      .set('persona', this.persona);
  }

  private validatePersona(persona: string): string {
    const personas = [
      "BEBE",
      "BIO",
      "CLINIC",
      "CRIANCA",
      "CRONICOS",
      "CUIDADOS_BASICOS",
      "FARMACINHA",
      "PANVEL_LOVERS",
      "PRE_NATAL",
      "SAUDE_MULHER",
      "USO_CONTINUO",
      "VIDA_SAUDAVEL"
    ];

    const personaWithUnderscore = persona?.trim().replace(/\s+/g, '_').toUpperCase();
    return personas.includes(personaWithUnderscore) ? personaWithUnderscore : 'NAO_LOGADO';
  }

  private getWheel(): void {
    if (this.isSpa) {
      if (!this.cookie.get("wheel")) {
        this.wheelService.getWheelForm('BLACK_FRIDAY')
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.store.dispatch(
              sendWheelForm({ form: WheelBuilder.build(data) })
            );
            const date = new Date();
            date.setDate(date.getDate() + 7);
            this.cookie.add("wheel", "true", date);
          });
      }
    }
  }

  sendCart($event): void {
    this.store.dispatch(CartAction.setInitCart({ cart: $event }));
  }

  setTGM(data): void {
    this.store.dispatch(tagManagerActions.pushBannerMenuTagManager({ banner: data }));
  }

  public shoppingCartDelete(): void {
    this.cookie.logout();
  }

  public cepEvent(event: { success?: Cart, error?: string }, reload = true) {
    if (event.success) {
      this._notificationService.cepEvent(event.success)
      if (reload) this.router.navigateByUrl('/');
    }
  }

  public notificationEvent(event: NotificationEvent) {
    this.windowRef?.dataLayer.push(event);
  }

  public sendRepurchaseEvent(event: RepurchaseEvent) {
    event.ecommerce.page = 'home';
    this.windowRef?.dataLayer.push(event);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    const pageHeightTotal = document.querySelector('body')?.scrollHeight || 100;
    const pageHeight = window.scrollY
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    const pageScrollPorcentage = pageHeight * 100 / pageHeightTotal;
    if (pageScrollPorcentage > 1 && this.isSpa && !this.eventsDispatched) {
      this.store.dispatch(setEventView())
      this.store.dispatch(getShowcasesChaordic());
      this.eventsDispatched = true;
    }
  }

  @HostBinding('class')
  get themeMode(): string { return this.globalTheme }
  get windowRef(): any { return window }
  get isSpa(): boolean { return isPlatformBrowser(this.platformId) }
}

