import { ShowCaseService } from '../../services/recommendation/show-case.service';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as ShowCaseAction from '../actions/show-case.actions';

@Injectable()
export class ShowCaseEffects {

  constructor(
    private actions$: Actions,
    private showCaseService: ShowCaseService
  ) {
  }

  getShowCase$ = createEffect(() => this.actions$.pipe(
      ofType(ShowCaseAction.loadShowCase),
      switchMap((props) => this.showCaseService.getShowCase(props.convenance, props.uf)
          .pipe(
            map(response => ShowCaseAction.loadShowCaseSuccess({data: response})),
            catchError((err) => of(
                ShowCaseAction.loadShowCaseFailure({error: err})
              ))
          ))
    )
  );

  getRecommendationCustomer$ = createEffect(() => this.actions$.pipe(
      ofType(ShowCaseAction.loadRecomendationCustomer),
      mergeMap((props) => this.showCaseService.getRecommendationCustomer(props.convenance, props.uf)
          .pipe(
            map(response => ShowCaseAction.loadRecomendationCustomerSuccess({data: response})),
            catchError((err) => of(
                ShowCaseAction.loadShowCaseFailure({error: err})
              ))
          ))
    )
  );

  getRecommendationCustom$ = createEffect(() => this.actions$.pipe(
      ofType(ShowCaseAction.loadRecomendationCustom),
      mergeMap((props) => this.showCaseService.getRecommendationCustom(props.convenance, props.uf)
        .pipe(
          map(response => ShowCaseAction.loadRecomendationCustomSuccess({data: response})),
          catchError((err) => of(
            ShowCaseAction.loadShowCaseFailure({error: err})
          ))
        ))
    )
  );
}