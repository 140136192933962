import { Banner } from '../services/home/banner-model';
import { MainBanner } from '../services/home/main-banner-model';
import { Story } from '../services/home/story-model';
import { Item as recommendationItem } from '../services/home/item-model';
import { Customer, } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';
import { Item } from '../services/shopping-cart/model/response/shopping-cart-response.model';

export class TagmanagerBuilder {

  static buildItemCart(item: Item): any {
    return {
      event: 'addToCart',
      addToCartProductName: item.name ? item.name : item.productName,
      addToCartProductSku: item.panvelCode as any as string,
      addToCartProductPrice: this.getPrice(item) as any as string,
    };
  }

  public static buildBannerMenu(banner: Banner): any {
    return {
      event: 'bannerMenu',
      bannerName: banner?.bannerName,
      bannerCampaign: banner?.campaign,
    };
  }

  public static buildBannerHome(banner: MainBanner | Banner): any {
    return {
      event: 'bannerHome',
      bannerName: banner?.bannerName,
      bannerCampaign: banner?.campaign,
    };
  }

  public static buildBannerNameDepartaments(banner: Banner | Story): any {
    return {
      event: 'bannerHome',
      bannerName: banner.bannerName,
      bannerCampaign: banner?.description,
    };
  }

  public static buildBannerViewPromotion(banner: Banner | Story): any {
    return {
      event: 'view_promotion',
      ecommerce: this.filterUndefinedProperties({
        creative_name: banner.bannerName,
        creative_slot: banner.campaign,
        promotion_id: banner.promotionId,
        location_id: banner.bannerPosition,
      })
    };
  }

  public static buildBannerSelectPromotion(banner: Banner | Story): any {
    return {
      event: 'select_promotion',
      ecommerce: this.filterUndefinedProperties({
        creative_name: banner.bannerName,
        creative_slot: banner.campaign,
        promotion_id: banner.promotionId,
        location_id: banner.bannerPosition,
      })
    };
  }

  public static buildUserData(customer: Customer) {
    let user_phone = '';
    if (customer?.deliveryContact) {
      const { ddi, ddd, contactNumber } = customer?.deliveryContact;
      user_phone = `+${ddi}(${ddd})${contactNumber}`
    }

    return {
      event: 'set_user_data',
      user_email: customer.email,
      user_phone,
    }
  }

  static showcaseEventClick(product: recommendationItem, recommendation?: { id: string, name: string }): any {
    return {
      event: 'select_item_list',
      ecommerce: this.buildGenericEcommerceInfos(product, recommendation.id, recommendation.name)
    }
  }

  static buildGenericEcommerceInfos(
    product: recommendationItem,
    id?: string,
    name?: string,
    variant?: { link: string, variant: string },
    eventName?: string,
    favorite?: boolean
  ): any {
    return this.filterUndefinedProperties({
      page: "home_page",
      location_id: "home_page",
      item_name: product?.name,
      form: eventName,
      item_id: product?.panvelCode,
      partner_name: product?.seller?.name,
      link: product?.seller?.linkUrl,
      currency: "BRL",
      original_price: product?.originalPrice,
      price: product?.discount?.dealPrice ? product?.discount?.dealPrice : product?.dealPrice,
      seller_name: product?.seller ? product.seller?.name : '',
      item_brand: product?.brandName,
      item_category: product?.categories?.[0]?.description,
      item_category2: product?.categories?.[1]?.description,
      item_category3: product?.categories?.[2]?.description,
      item_list_id: id || '',
      item_list_name: name || '',
      variant: variant ? variant?.variant : '',
      click_link: variant ? variant.link : '',
      ...this.favoriteEventProp(favorite)
    })
  }

  private static favoriteEventProp(favorite: boolean) {
    const isFavoriteEvent = typeof favorite !== 'undefined' && favorite !== null;
    if (isFavoriteEvent) {
      const status = favorite ? 'favorite' : 'unfavorite'
      return { status }
    }
    return null;
  }

  static getPrice(product: Item): number {
    if (!!product?.discount) {
      return product?.discount?.dealPrice;
    }
    return product?.originalPrice;
  }

  private static filterUndefinedProperties(obj: Record<string, any>): Record<string, any> {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => typeof value !== 'undefined' && value !== null && value !== '')
    );
  }

}
