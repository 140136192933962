import {Directive, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { ShowcaseStory } from 'src/app/services/home/showcase-story-model';
import { Story } from 'src/app/services/home/story-model';

@Directive({
  selector: '[appShowcaseStoryMobileBase]'
})
export class ShowcaseStoryMobileBaseDirective implements OnInit {
  @Input() story: ShowcaseStory;
  @Input() layoutType: 'singleRow' | 'doubleRow';
  storyList: Story[];
  time: number;
  currentIndex: number;
  timer: any;

  constructor(@Inject(PLATFORM_ID) private platformId?) {
    this.time = 7000;
    this.currentIndex = 0;
    this.story = {} as ShowcaseStory;
    this.story.stories = [{}] as Story[];
  }

  ngOnInit(): void {
    this.storyList = this.layoutType === 'singleRow' ? this.splitData(this.story.stories, 3) : this.splitData(this.story.stories, 6);
    if (isPlatformBrowser(this.platformId)) {
      this.timer = setInterval(() => this.nextImage(), this.time);
      this.addSlide();
    }
  }

  splitData(data: Story[], qtdPerGroup) {
    const novoArray = [];
    for (let i = 0; i < data?.length; i = i + qtdPerGroup) {
      novoArray.push(data.slice(i, i + qtdPerGroup));
    }
    return novoArray;
  }

  public updateInterval() {
    clearInterval(this.timer);
    this.removeSlide();
    this.timer = setInterval(() => {
      this.nextImage();
    }, this.time);
  }

  public previousImage() {
    this.updateInterval();
    if (this.currentIndex === 0) {
      this.currentIndex = this.storyList?.length - 1;
    } else {
      this.currentIndex--;
    }
    this.removeSlide();
    this.addSlide();
  }

  public nextImage() {
    this.updateInterval();
    if (this.storyList?.length > 0) {
      this.currentIndex++;
      if (this.currentIndex === this.storyList?.length) {
        this.currentIndex = 0;
      }
      this.removeSlide();
      this.addSlide();
    }
  }

  public changeSlideByIcon(nextSlide) {
    this.removeSlide();
    this.currentIndex = nextSlide;
    this.addSlide();
  }

  public removeSlide() {
    this.storyList[this.currentIndex].status = false;
  }

  public addSlide() {
    this.storyList[this.currentIndex].status = true;
  }
}
