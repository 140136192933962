import { Component, Input, OnInit } from '@angular/core';
import { Observable, tap } from "rxjs";
import { Showcase } from "../../services/home/showcase-model";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/state/app.state";
import { getShowCaseCustom } from "../../store/selectors/show-case.selectors";
import { sendShowcasesImpression } from 'src/app/store/actions/event.actions';

@Component({
  selector: 'app-home-content-custom',
  templateUrl: './home-content-custom.component.html',
  styleUrls: ['./home-content-custom.component.scss']
})
export class HomeContentCustomComponent implements OnInit {

  public showcaseCustom$: Observable<Showcase[]>;
  @Input() public theme: string;
  public showcases: Showcase[] = [];

  constructor(private store: Store<AppState>) {
    this.showcaseCustom$ = this.store.select(getShowCaseCustom);
  }

  ngOnInit(): void {
    this.showcaseCustom$.pipe(
      tap(showcases => {
        if (showcases) {
          showcases.forEach(showcase => {
            this.store.dispatch(sendShowcasesImpression({ impressionUrl: showcase?.recommendation.impressionUrl }));
            const items = showcase.recommendation?.items?.reduce((acc, current) => {
              acc.push({ ...current, link: `${current.link}?trackingUrl=${current.trackingUrl}` })
              return acc;
            }, []);
            this.showcases = [...this.showcases, { ...showcase, recommendation: { ...showcase.recommendation, items } }]
          })
        }
      })
    ).subscribe();
  }

}
