import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddItemBuilder } from '../../services/shopping-cart/builder/add-item-builder';
import { Item } from '../../services/shopping-cart/model/response/shopping-cart-response.model';
import { ShoppingCartApi } from '../../services/shopping-cart/shopping-cart.api';
import * as CartAction from '../actions/cart.actions';
import * as ItemAction from '../actions/item.actions';
import * as tagManagerActions from '../actions/tagmanager.actions';
import { ItemResumeBuilder } from '../builder/item-builder';
import { AppState } from '../state/app.state';

@Injectable()
export class ItemEffects {
  addItemToCart$ = createEffect(() => this.actions$.pipe(
    ofType(ItemAction.addItemToCart),
    mergeMap((props) => this.shoppingCartApi.addItemToCart(AddItemBuilder.build(props.item))
      .pipe(
        map(cart => {
          if (!this.cookieService.get('shoppingCartId'))  this.cookieService.set(environment.cookieCartName, cart.shoppingCartId, {
            path: '/',
            domain: environment.domain
          });
          const item: Item = cart.items.find(element => element.panvelCode === Number(props.item.panvelCode));
          if (item) {
            this.store.dispatch(tagManagerActions.pushItemToCart({item}));
          }
          return CartAction.setInitCart({cart});
        }),
        catchError((err) => of(
            ItemAction.addItemToCartFailure({error: ItemResumeBuilder.suggestedUserActionsBuilder((err))})
          ))
      )
    ))
  );

  constructor(private actions$: Actions,
              private cookieService: CookieService,
              private store: Store<AppState>,
              private shoppingCartApi: ShoppingCartApi) {
  }
}
