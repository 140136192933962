import {Component} from '@angular/core';
import {ShowcaseStoryMobileBaseDirective} from '../showcase-story-mobile-base.directive';

@Component({
  selector: 'app-showcase-story-mobile-carousel',
  templateUrl: './showcase-story-mobile-carousel.component.html',
  styleUrls: ['./showcase-story-mobile-carousel.component.scss']
})
export class ShowcaseStoryMobileCarouselComponent extends ShowcaseStoryMobileBaseDirective {
}
