<div class="info-bar-mobile" #infoBarMobile>
  <div class="info-bar-mobile__content" appSwipe (swipeLeft)="nextImage()" (swipeRight)="previousImage()">
    <app-info-bar-item *ngIf="toolBar[currentIndex] as item" [title]="item.title" [description]="item.description"
      [link]="item.link" [tag]="item.tag" type="mobile"> </app-info-bar-item>
  </div>

  <div class="info-bar-mobile__dot-menu">
    <div *ngFor="let item of toolBar; let i = index" (click)="changeSlideByIcon(i)" class="dot-icon"
      [ngClass]="{'actived' : item.status}"></div>
  </div>
</div>