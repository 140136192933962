import { Component } from '@angular/core';
import { ShowcaseRecommendationDirective } from '../showcase-recommendation.directive';


@Component({
  selector: 'app-showcase-recommendation-slide',
  templateUrl: './showcase-recommendation-slide.component.html',
  styleUrls: ['./showcase-recommendation-slide.component.scss']
})
export class ShowcaseRecommendationComponent extends ShowcaseRecommendationDirective {
}
