<div class="story-mobile">
  <div class="card">
    <div class="titles">
      <div class="title">{{ story.title }}</div>
      <div class="subtitle">{{ story.subtitle }}</div>
    </div>
    <div class="story-carousel" appSwipe (swipeLeft)="nextImage()" (swipeRight)="previousImage()" >
      <div class="slides">
        <div *ngFor="let story of storyList; index as i" class="story">
          <app-showcase-story-mobile id="showcase-story-mobile-{{i+1}}" [layoutType]="layoutType"
                                     [ngClass]="{ 'selected' : story.status }" [story]="story"
                                     class="showcase-story-mobile">
          </app-showcase-story-mobile>
        </div>
      </div>
    </div>
    <div class="menu">
      <div (click)="changeSlideByIcon(i)" *ngFor="let item of storyList; let i = index"
           [ngClass]="{'icon--actived' : item.status}" class="icon">
      </div>
    </div>
  </div>
</div>
