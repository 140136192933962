import { Component, Input, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Showcase } from '../../services/home/showcase-model';
import { getShowCaseCustomer } from "../../store/selectors/show-case.selectors";
import { AppState } from "../../store/state/app.state";

@Component({
  selector: 'app-home-content-customer',
  templateUrl: './home-content-customer.component.html',
  styleUrls: ['./home-content-customer.component.scss']
})
export class HomeContentCustomerComponent implements OnInit {

  public showcaseCustomer$: Observable<Showcase[]>;
  @Input() public theme: string;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.showcaseCustomer$ = this.store.select(getShowCaseCustomer);
  }
}
