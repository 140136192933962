import {createReducer, on} from '@ngrx/store';
import {updateCarousel} from '../actions/index-carousel.actions';

export const carouselFeatureKey = 'carousel';

export interface CarouselState {
  index: number;
}

export const appInitialState: CarouselState = {
  index: 0,
};

export const reducer = createReducer(
  appInitialState,
  on(updateCarousel, (state, {index}) => {
    state = {
      ...state,
      index
    };
    return state;
  })
);
