import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ShoppingCartApi } from "../shopping-cart/shopping-cart.api";
import { CartStateBuilder } from "./cart-state.builder";
import { CartStateModel } from "./cart-state.model";
import { Cart } from "panvel-utils-lib/lib/models/shopping-cart-response.model";

@Injectable()
export class CartResolver implements Resolve<CartStateModel> {
  constructor(
    private shoppingCartApi: ShoppingCartApi,
    private cookieService: CookieService,
  ) { }

  private static getShoppingCartId(
    shoppingCartId: string,
    headers: HttpHeaders
  ): string {
    return !!headers.get(environment.cookieCartName)
      ? headers.get(environment.cookieCartName)
      : shoppingCartId;
  }

  resolve(): Observable<CartStateModel> {
    if (
      this.cookieService.hasKey("shoppingCartId") ||
      this.cookieService.hasKey("refresh_token")
    ) {
      const shoppingCartId = this.cookieService.get(
        environment.cookieCartName
      );
      if (shoppingCartId) {
        return CartStateBuilder.build(shoppingCartId).pipe(
          mergeMap((cartState: CartStateModel) =>
            this.findCart(cartState.shoppingCartId)
          )
        );
      } else {
        return this.createCookieState();
      }
    }
    return of({ cart: {} });
  }

  private findCart(
    shoppingCartId: string,
  ): Observable<CartStateModel> {
    return this.shoppingCartApi.findCart(shoppingCartId).pipe(
      map((shoppingCart) => {
        const body = shoppingCart.body as Cart;
        const newCartId = CartResolver.getShoppingCartId(
          shoppingCartId,
          shoppingCart.headers
        );
        this.cookieService.put(environment.cookieCartName, newCartId, {
          path: "/",
          domain: environment.domain,
        });

        if (!this.cookieService.hasKey("customer_persona") && body?.customer?.persona) {
          this.cookieService.put('customer_persona',
            body?.customer?.persona,
            {
              path: "/",
              domain: environment.domain,
            }
          );
        }

        return {
          shoppingCartId: newCartId,
          load: false,
          cart: body,
        };
      }),
      catchError(() => of(({
        shoppingCartId: shoppingCartId,
        load: false,
        cart: {},
      })))
    );
  }
  private createCookieState(): Observable<CartStateModel> {
    return this.shoppingCartApi.createCart().pipe(
      switchMap((resp) => this.findCart(resp.shoppingCartId)),
      tap((shoppingCart) => shoppingCart && this.setCartInCookie(shoppingCart),
      ),
      catchError(() => of(({
        shoppingCartId: '',
        load: false,
        cart: {},
      }))),
    );
  }

  private setCartInCookie(shoppingCart: Cart): void {
    this.cookieService.put(
      environment.cookieCartName,
      shoppingCart?.shoppingCartId,
      {
        path: "/",
        domain: environment.domain,
      }
    );
  }
}
