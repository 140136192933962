import {Component, Input} from '@angular/core';
import {Story} from 'src/app/services/home/story-model';

@Component({
  selector: 'app-showcase-story-mobile',
  templateUrl: './showcase-story-mobile.component.html',
  styleUrls: ['./showcase-story-mobile.component.scss']
})
export class ShowcaseStoryMobileComponent  {


  @Input() story: Story[];
  @Input() layoutType: 'singleRow' | 'doubleRow';
}
