import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { ImageBanner } from 'src/app/services/banners/models/banner.model';

const BREAKPOINTS_LIST = [
  '(min-width: 1025px)',
  '(min-width: 768px) and (max-width: 1024px)',
  '(min-width: 320px) and (max-width: 480px)'
];

const BREAKPOINTS_MAP: { [key: string]: string } = {
  '(min-width: 1025px)': 'DESKTOP',
  '(min-width: 768px) and (max-width: 1024px)': 'TABLET',
  '(min-width: 320px) and (max-width: 480px)': 'MOBILE'
};

@Component({
  selector: 'app-main-banner-image',
  templateUrl: './main-banner-image.component.html',
  styleUrls: ['./main-banner-image.component.scss']
})
export class MainBannerImageComponent implements OnInit {
  @Input() banner: ImageBanner[];
  public bannerIndex = 0;
  public destroy$ = new Subject();

  constructor(protected breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.observerBreakpoint();
  }

  private observerBreakpoint() {
    this.breakpointObserver.observe(BREAKPOINTS_LIST)
      .pipe(
        takeUntil(this.destroy$),
        map(({ breakpoints }) => {
          for (const query of Object.keys(breakpoints)) if (breakpoints[query]) return query;
          return null;
        })
      ).subscribe(breakpoint => {
        if (breakpoint) {
          const index = this.banner.findIndex((image) => image?.device === BREAKPOINTS_MAP[breakpoint])
          if (index >= 0) this.bannerIndex = index;
        }
      });
  }

}