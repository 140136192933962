import {Component, Input} from '@angular/core';
import { TopBar } from 'src/app/services/home/top-bar-model';

@Component({
  selector: 'app-shipping-bar',
  templateUrl: './shipping-bar.component.html',
  styleUrls: ['./shipping-bar.component.scss']
})
export class ShippingBarComponent {
  @Input() public shippingBar: TopBar;
}
