import { Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowRefService } from '../../services/window-ref.service';
import { environment } from "../../../environments/environment";
import { MainBanner } from "panvel-utils-lib/lib/components/banner/models/main-banner";
import { TagmanagerBuilder } from "../../builders/tagmanager-builder";
import { StaticsResponse } from 'src/app/services/home/statics.model';

@Component({
  selector: 'app-home-content-statics',
  templateUrl: './home-content-statics.component.html',
  styleUrls: ['./home-content-statics.component.scss'],
})
export class HomeContentStaticsComponent {

  @Input() statics: StaticsResponse;
  @Input() showStaticBanner: boolean = false;

  public innerWidth: number;
  public theme = environment.theme

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRefService
  ) {
    this.updateInnerWidth();
  }

  sendEventBannerToTagManager(banner: MainBanner) {
    window?.dataLayer?.push(
      TagmanagerBuilder.buildBannerSelectPromotion({ ...banner, bannerPosition: 'header' })
    );
    window.dataLayer.push(TagmanagerBuilder.buildBannerHome(banner));
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateInnerWidth();
  }

  updateInnerWidth(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = this.windowRef.nativeWindow.innerWidth;
    }
  }

}
