import {Observable, of} from 'rxjs';
import {CartStateModel} from './cart-state.model';
import {Builder} from 'builder-pattern';

export class CartStateBuilder {

  static build(shoppingCartId: string): Observable<CartStateModel> {
    return of(Builder<CartStateModel>()
      .shoppingCartId(shoppingCartId)
      .load(!!shoppingCartId)
      .build());
  }
}
