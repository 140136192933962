<div class="header-nav">
  <div class="header-light">
    <lib-panvel-header
      [static]="statics$ | async"    
      [cart]="cart$ | async"
      [config]="config"
      (deleteShoppingCart)="shoppingCartDelete()" 
      (newCart)="sendCart($event)"
    ></lib-panvel-header>
  </div>
</div>
<lib-not-found></lib-not-found>
<lib-p-footer [theme]="globalTheme"></lib-p-footer>