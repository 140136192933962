<lib-showcase-title-v2 [theme]="theme" [title]="showcaseStory.title"
  [subtitle]="showcaseStory?.subtitle"></lib-showcase-title-v2>
<div class="brand-carousel" data-cy="carousel">
  <div (click)="prev()" data-cy="prev-carousel" class="left-button">
    <div class="icn-left"></div>
  </div>
  <ul [className]="size === 'small' ? 'slides' : 'slides big'">
    <li *ngFor="let brand of storyList | slice:0:7" class="brand">
      <app-showcase-story [size]="size" [story]="brand"></app-showcase-story>
    </li>
  </ul>
  <div (click)="next()" data-cy="next-carousel" class="right-button">
    <div class="icn-right"></div>
  </div>
</div>