import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/state/app.state';
import { isPlatformBrowser } from '@angular/common';
import { loadDataDogs } from './store/actions/data-dog.actions';
import { getStatics } from './store/actions/statics.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platform
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.store.dispatch(loadDataDogs());
      this.store.dispatch(getStatics());
    }
  }
}
