<div *ngIf="layoutType === 'singleRow'" [class]="layoutType">
  <div *ngFor="let story of story" class="story margin">
    <a [href]="story.link" aria-label="story">
      <div class="img-container">
        <img loading="lazy" [alt]="story.description ? story.description : 'Panvel marcas'" [src]="story.image">
      </div>
    </a>
    <p class="description">{{ story.description }}</p>
  </div>
</div>

<div *ngIf="layoutType === 'doubleRow'" [class]="layoutType">
  <div class="container">
    <div *ngFor="let story of story; index as i;" class="position-{{i + 1}}">
      <div class="story">
        <a [href]="story.link" aria-label="story">
          <div class="img-container">
            <img loading="lazy" [alt]="story.description ? story.description : 'Panvel marcas'" [src]="story.image">
          </div>
        </a>
        <p class="description">{{ story.description }}</p>
      </div>
    </div>
  </div>
</div>
