import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss']
})
export class ArrowButtonComponent  {

  @Output()
  leftButton = new EventEmitter<any>();

  @Output()
  rightButton = new EventEmitter<any>();

  @Input()
  recommendationTitle: string;

  leftClick(): void{
    window.dataLayer.push({
      'event':'see_more_left',
      'item_list_id': this.recommendationTitle,
      'item_list_name': this.recommendationTitle,
    });
    this.leftButton.emit()
  }

  rightClick(): void{
    window.dataLayer.push({
      'event':'see_more_right',
      'item_list_id': this.recommendationTitle,
      'item_list_name': this.recommendationTitle,
    });
    this.rightButton.emit()
  }
}
