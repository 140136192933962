import { createReducer, on } from '@ngrx/store';
import * as CartAction from '../actions/cart.actions';
import { Cart } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';
import { environment } from 'src/environments/environment';

export const cartFeatureKey = 'cart';

export interface CartState {
  totalItems: number;
  name: string;
  email: string;
  theme?: string;
  cart: Cart;
}

export const initialState: CartState = {
  totalItems: 0,
  name: null,
  email: null,
  theme: environment.theme,
  cart: null
};

export const reducer = createReducer<CartState>(
  initialState,
  on(CartAction.setInitCart, (state, action): CartState => ({
    ...state,
    totalItems: action.cart?.cartTotals?.totalItems,
    name: action.cart?.customer?.firstName,
    email: action.cart?.customer?.email,
    theme: action.cart?.theme,
    cart: action.cart
  }))
);
