import { Builder } from "builder-pattern";
import { WheelRequest } from "../services/home/wheel-request.model";

export class WheelBuilder {
  static build(formWheel: any): WheelRequest {
    return Builder<WheelRequest>()
      .coupon(formWheel.itemSelected.coupon)
      .document(formWheel.cpf)
      .email(formWheel.email)
      .name(formWheel.name)
      .optin(formWheel.terms)
      .origin(formWheel.origin)
      .captcha(formWheel.token)
      .build();
  }
}
