import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as DataDogAction from '../actions/data-dog.actions';
import {tap} from 'rxjs/operators';
import {DatadogService} from '../../services/data-dog/datadog.service';


@Injectable()
export class DataDogEffects {

  setDatadog = createEffect(() => this.actions$.pipe(
    ofType(DataDogAction.loadDataDogs),
    tap(() => this.srv.setDataDog())
  ), {dispatch: false});

  constructor(private actions$: Actions, private srv: DatadogService) {}

}
