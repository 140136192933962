import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as fromCart from './cart.reducer';
import * as fromShowCase from './show-case.reducer';
import * as fromTagManager from './tagmanager.reducer';
import * as fromStaticsReducer from './statics.reducer';
import * as carousel from './index-carousel.reducer';
import * as fromLazyLoading from './lazy-loading.reducer';
import * as chaordic from './chaordic.reducer';
import * as banners from './banner.reducer';

export const reducers: ActionReducerMap<AppState> = {
  [fromCart.cartFeatureKey]: fromCart.reducer,
  [fromTagManager.featureKey]: fromTagManager.reducer,
  [carousel.carouselFeatureKey]: carousel.reducer,
  [fromStaticsReducer.StaticsFeatureKey]: fromStaticsReducer.reducer,
  [fromShowCase.ShowCaseFeatureKey]: fromShowCase.reducer,
  [fromLazyLoading.LazyLoadingFeatureKey]: fromLazyLoading.reducer,
  [chaordic.chaordicFeatureKey]: chaordic.reducer,
  [banners.bannerFeatureKey]: banners.reducer,
};