import {ItemsRequest} from '../model/request/items-request.model';
import {Builder} from 'builder-pattern';
import {AddItemsRequest} from '../model/request/add-item-request.model';
import {IAddItemToCart} from '../../../shared/interfaces/IAddItemToCart';

export class AddItemBuilder {
  static build(item: IAddItemToCart): AddItemsRequest {
    return Builder<AddItemsRequest>()
      .items([Builder<ItemsRequest>()
        .panvelCode(item.panvelCode)
        .quantity(item.quantity)
        .build()])
      .build();
  }
}
