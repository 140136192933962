<div *ngIf="(showcaseCustomer$ | async) as showcases">
  <div *ngFor="let showcase of showcases" class="case">
    <app-showcase-recommendation-slide
      *ngIf="showcase?.type === 'recommendation'"
      [recommendation]="showcase"
      [theme]="theme"
      [isCustomer]="true"
    ></app-showcase-recommendation-slide>
  </div>
</div>
