import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StaticsService } from '../../services/home/statics.service';
import { getStatics, getStaticsSuccess } from '../actions/statics.actions';

@Injectable()
export class StaticsEffects {
  getStatics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatics),
      mergeMap(() => this.staticsService.getStaticsHome().pipe(
        map((response) => getStaticsSuccess({ data: response })),
        catchError((error) => of(error)),
      )),
    ),
  );

  constructor(private actions$: Actions, private staticsService: StaticsService) { }
}