import { createReducer, on } from '@ngrx/store';
import { getStaticsSuccess } from '../actions/statics.actions';
import { StaticsResponse } from 'src/app/services/home/statics.model';

export const StaticsFeatureKey = 'statics';

export interface StaticsState {
  statics: StaticsResponse
}

export const initialState: StaticsState = {
  statics: null,
};

export const reducer = createReducer(
  initialState,
  on(getStaticsSuccess, (state, action): StaticsState =>
  ({
    ...state,
    statics: action.data
  })),
);