import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class AddShoppingCartInterceptor implements HttpInterceptor {
constructor(private cookieService: CookieService) {}

intercept(
  req: HttpRequest<any>,
  next: HttpHandler
): Observable<HttpEvent<any>> {
  return next.handle(req).pipe(
    filter((req) => req instanceof HttpResponse),
    tap((response: HttpResponse<any>) => {
      const cookieValue = response.headers.get(environment.cookieCartName);
      if (!!cookieValue && req.url.includes('cart/zip-code')) {
        this.saveCookie(cookieValue);
      }
    })
  );
}

saveCookie(newCartId: string): void {
  this.cookieService.put(environment.cookieCartName, newCartId, {
    path: "/",
    domain: environment.domain,
  });
}
}
