<div class="cookies desktop" *ngIf="showCookiesAccept">
  <div class="text-cookies">
    <div class="info">
    <span> COLETAMOS DADOS PARA MELHORAR O DESEMPENHO E SEGURANÇA DO SITE, ALÉM DE PERSONALIZAR CONTEÚDO E ANÚNCIOS.
    VOCÊ PODE CONFIGURAR SUAS PREFERÊNCIAS EM NOSSO SITE E CONFERIR NOSSA </span>
      <a href="institucional.do?secao=duvidasSeguranca" aria-label="Política de Privacidade">
        <span class="privacy">Política de Privacidade</span>
      </a>.
    </div>
    <div class="container-button">
      <button id="input-aceite-first" (click)="acceptCookie()" class="accept-button">EU CONCORDO</button>
    </div>
  </div>
</div>

