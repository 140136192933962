import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as bannerReducer from '../reducers/banner.reducer';

export const getBannersFeatureState = createFeatureSelector<bannerReducer.BannerState>(
  bannerReducer.bannerFeatureKey
);

export const getBannersHome = createSelector(
  getBannersFeatureState,
  state => state.banners
);
