import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { StaticsResponse } from './statics.model';

@Injectable({
  providedIn: 'root'
})
export class StaticsService {

  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) { }

  getStaticsHome(): Observable<StaticsResponse> {
    const options = {
      'app-token': environment.appToken,
      'user-id': '8601417',
      'client-ip': '1'
    };
    return this.http.get<StaticsResponse>(`${this.baseHref}api/v2/home/statics`, { headers: options });
  }
}