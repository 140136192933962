import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as chaordic from '../reducers/chaordic.reducer';

export const getChaordicFeature = createFeatureSelector<chaordic.ChaordicState>(
  chaordic.chaordicFeatureKey
);

export const getShowcaseChaordic = createSelector(
  getChaordicFeature,
  state => state.showcases
);
