import { Injectable } from '@angular/core';
import { Observable,  BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FingerStateService {

  private fingerStates = new BehaviorSubject<Map<string, string>>(new Map());

  constructor() {

  }

  public setFingerStates(state: Map<string, string>) {
    this.fingerStates.next(state);
  }

  public getFingerStates(): Observable<Map<string, string>> {
    return this.fingerStates.asObservable();
  }
}
