import { createReducer, on } from '@ngrx/store';
import * as LazyLoadingAction from '../actions/lazy-loading.actions';

export const LazyLoadingFeatureKey = 'lazyloading';
export interface LazyLoadingState {
  lazyLoading: boolean;
}

export const initialState: LazyLoadingState = {
  lazyLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(LazyLoadingAction.lazyLoadingStatus, (state, action): LazyLoadingState =>
  ({
    ...state,
    lazyLoading: action.lazyLoading,
  })),
);
