import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[appInfoBarItemBase]'
})
export class InfoBarItemBaseDirective {

  @Input() public title: string;
  @Input() public description: string;
  @Input() public link: string;
  @Input() public image: string;
  @Input() public tag: string;
  @Input() public type = 'desktop';

}
