import { isPlatformBrowser } from "@angular/common";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { FingerPrintService } from "panvel-utils-lib";
import { Observable } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { FingerStateService } from "src/app/services/finger/finger.state.service";

@Injectable()
export class FingerInterceptor implements HttpInterceptor {
  constructor(
    private fingerPrintService: FingerPrintService,
    private fingerStateService: FingerStateService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId)) {
      return this.fingerStateService.getFingerStates().pipe(
        take(1),
        switchMap((state) =>
          state.size > 0
            ? next.handle(this.cloneRequest(state, req))
            : this.fingerPrintService.generateFingerPrint().pipe(
                tap((stateFull) =>
                  this.fingerStateService.setFingerStates(stateFull)
                ),
                switchMap((statesFull) =>
                  next.handle(this.cloneRequest(statesFull, req))
                )
              )
        )
      );
    }
    return next.handle(req);
  }

  private cloneRequest(
    map: Map<string, string>,
    req: HttpRequest<any>
  ): HttpRequest<any> {
    const headers = {};
    const translator = { platform: "source", fingerPrint: "finger-print" };

    map.forEach((value, key) => (headers[translator[key]] = value));
    req = req.clone({
      setHeaders: {
        ...headers,
      },
    });
    return req;
  }
}
