import { Component, Input } from '@angular/core';
import { ToolBar } from 'src/app/services/home/tool-bar-model';


@Component({
  selector: 'app-info-bar-desktop',
  templateUrl: './info-bar-desktop.component.html',
  styleUrls: ['./info-bar-desktop.component.scss']
})
export class InfoBarDesktopComponent {
  @Input() public toolBar: ToolBar[];
}
