<div class="main-info-bar">
  <div class="info-bar-desktop" data-cy="info-bar">
    <app-info-bar-item
      *ngFor="let info of toolBar"
      [title]="info.title"
      [description]="info.description"
      [link]="info.link"
      [tag]="info.tag"
    ></app-info-bar-item>
  </div>
</div>

