import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  setDataDog(): void {
    datadogRum.init({
      applicationId: 'fbcd2a51-b4dd-40de-a239-356c5baef076',
      clientToken: 'pubc2f3a474d0cdcb3e9b5d37f4434ef953',
      site: 'datadoghq.com',
      service: 'panvel-home',
      env: environment.production ? 'prd' : 'hml',
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
  }
}
