import { createAction, props } from '@ngrx/store';
import { AffiliateEvents } from 'src/app/services/home/affiliate.model';
import { ShowcaseChaordic } from 'src/app/services/home/showcase.chaordic-model';
import { EventModel } from 'src/app/services/shopping-cart/model/request/event';

export const setEventSearch = createAction(
  '[ Event ] Set event',
  props<{ data: EventModel }>()
);

export const setEventView = createAction(
  '[ Event ] Set event view'
);

export const setEventClick = createAction(
  '[ Event ] Set event click affiliate',
  props<{ affiliateEvents: AffiliateEvents }>()
);

export const eventSearchSuccess = createAction(
  '[ Event ] Set success event',
);

export const getShowcasesChaordic = createAction(
  '[ CHAORDIC ] - GET SHOWCASES CHAORDIC'
);

export const setShowcasesChaordic = createAction(
  '[ CHAORDIC ] - SET SHOWCASES CHAORDIC',
  props<{ showcases: ShowcaseChaordic[] }>()
);

export const errorShowcasesChaordic = createAction(
  '[ CHAORDIC ] - ERROR SHOWCASE CHAORDIC',
  props<{ error: string }>()
);

export const sendShowcasesImpression = createAction(
  '[ CHAORDIC ] - SEND URL IMPRESSION',
  props<{ impressionUrl: string }>()
);

export const sendShowcasesClick = createAction(
  '[ CHAORDIC ] - SEND URL CLICK',
  props<{ trackingId: string, panvelCode: number }>()
);


export const successShowcasesClick = createAction(
  '[ CHAORDIC ] - SUCCESS URL CLICK',
);

export const successShowcasesImpression = createAction(
  '[ CHAORDIC ] - SUCCESS URL IMPRESSION'
);
