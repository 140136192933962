import { createReducer, on } from '@ngrx/store';
import { ContentBanner } from 'src/app/services/banners/models/banner.model';
import { updateBanners } from '../actions/banner.actions';

export const bannerFeatureKey = 'banners';

export interface BannerState {
  banners: ContentBanner[];
}

export const initialState: BannerState = {
  banners: null,
};

export const reducer = createReducer(
  initialState,
  on(updateBanners, (state, action): BannerState =>
  ({
    ...state,
    banners: action.banners
  })),
);
