
<section *ngIf="banner" class="main-banner-image">
  <a 
    class="main-banner-image__url"
    [id]="'banner-vitrine-'+ banner[bannerIndex]?.redirectUrl"
    [href]="banner[bannerIndex]?.redirectUrl"
    aria-label="Banner de anúncio"
  >
    <img
      class="main-banner-image__img" 
      loading="lazy"
      *ngIf="banner[bannerIndex]?.imageUrl" 
      [src]="banner[bannerIndex].imageUrl"
      [alt]="banner[bannerIndex].screenReaderDescription ?? 'Banner' "
      height="auto"
      width="1260"
    >
  </a>
</section>