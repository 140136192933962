import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { Observable } from 'rxjs';
import { BannerResponse } from './models/banner.model';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) { }

  public getMainBanner(params: HttpParams): Observable<BannerResponse> {
    return this.http.get<BannerResponse>(`${this.baseHref}api/v2/banners`, { params });
  }
}