import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { RefreshApi } from "src/app/services/shopping-cart/refresh.api";
import { environment } from "../../../environments/environment";
 import { Refresh } from "src/app/services/shopping-cart/model/request/login-response.model";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private cookie: CookieService, private service: RefreshApi) {}
  private refresh$ = new BehaviorSubject(true);
  private success$ = new Subject();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !req.url.includes("oauth/refresh")) {
            const refresh_Token = this.cookie.get("refresh_token");
            if (refresh_Token) {
              return this.refresh$.asObservable().pipe(
                take(1),
                switchMap(data => {
                  if (data) {
                    this.refresh$.next(false);
                    return this.service.refreshToken(refresh_Token).pipe(
                      switchMap((token) => {
                        this.refresh$.next(true);
                        this.removeEtokenAndUpdate(
                          token
                        );
                        this.success$.next(true);
                        return next.handle(req.clone());
                      })
                    );
                  } else {
                    return this.success$.asObservable()
                      .pipe(take(1), switchMap(() => next.handle(req.clone()))
                    );
                  }
              }))
            } else {
              this.removeCookie('eToken');
              this.removeCookie('refresh_token');
              window.location.href = environment.panvelLogin;
              return throwError(() => error);
            }
          } else if (req.url.includes("oauth/refresh")) {
            this.removeCookie('eToken');
            this.removeCookie('refresh_token')
            window.location.href = environment.panvelLogin;
          }
          return throwError(() => error);
        }
      })
    );
  }

  private removeEtokenAndUpdate(token: Refresh): void {
    this.cookie.put("eToken", token.access_token, {
      domain: environment.domain,
      path: "/",
      //expires: token.expiresDateIn
    });
    this.cookie.put("refresh_token", token.refresh_token, {
      domain: environment.domain,
      path: "/",
      //expires: token.refreshExpiresIn
    });
  }

  private removeCookie(cookieName: string) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}

