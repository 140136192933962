import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const appToken = environment.appToken;
      const shoppingCartId = this.cookieService.get("shoppingCartId");
      const sessionId = this.cookieService.get("sessionId");
      if (shoppingCartId) {
        return next.handle(
          req.clone({
            setHeaders: {
              "app-token": appToken,
              shoppingCartId,
              theme: environment.theme,
              sessionId
            },
          })
        );
      }
      return next.handle(req.clone({setHeaders: {'app-token': appToken, 'sessionId': sessionId}}));
  }
}
