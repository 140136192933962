import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ShowcaseRecommendationComponent
} from 'src/app/component/showcase-recommendations/showcase-recommendation-slide/showcase-recommendation-slide.component';
import {
  ShowcaseStoryMobileCarouselComponent
} from 'src/app/component/showcase-story-mobile/showcase-story-mobile-carousel/showcase-story-mobile-carousel.component';
import { ShowcaseStoryCarouselComponent } from 'src/app/component/showcase-story/showcase-story-carousel/showcase-story-carousel.component';
import { ShowcaseStoryComponent } from 'src/app/component/showcase-story/showcase-story/showcase-story.component';
import {
  ShowcaseStoryMobileComponent
} from 'src/app/component/showcase-story-mobile/showcase-story-mobile/showcase-story-mobile.component';
import { ArrowButtonComponent } from '../../component/arrow-button/arrow-button.component';
import {CardItemModule, PUtilsLibModule, SwipeDirectiveModule} from "panvel-utils-lib";
import { HomeContentShowcaseComponent } from '../home-content-showcase/home-content-showcase.component';
import { BannerScrollCarouselComponent } from 'src/app/component/banner-scroll/banner-scroll-carousel/banner-scroll-carousel.component';
import { BannerNoscrollComponent } from 'src/app/component/banner-noscroll/banner-noscroll/banner-noscroll.component';
import { ContentBannerComponent } from 'src/app/component/content-banner/content-banner.component';
import {
  ShowcaseRecommendationGridComponent
} from 'src/app/component/showcase-recommendations/showcase-recommendation-grid/showcase-recommendation-grid.component';
import { CardBannerNoscrollComponent } from 'src/app/component/banner-noscroll/card-banner-noscroll/card-banner-noscroll.component';
import { CardBannerScrollComponent } from 'src/app/component/banner-scroll/card-banner-scroll/card-banner-scroll.component';
import { ShowcaseRecommendationDirective } from 'src/app/component/showcase-recommendations/showcase-recommendation.directive';

@NgModule({
  declarations: [
    ShowcaseRecommendationComponent,
    ShowcaseStoryCarouselComponent,
    ShowcaseStoryMobileCarouselComponent,
    ShowcaseStoryMobileComponent,
    ShowcaseStoryComponent,
    ArrowButtonComponent,
    HomeContentShowcaseComponent,
    BannerScrollCarouselComponent,
    BannerNoscrollComponent,
    ContentBannerComponent,
    ShowcaseRecommendationGridComponent,
    CardBannerNoscrollComponent,
    CardBannerScrollComponent,
    ShowcaseRecommendationDirective,
  ],
    imports: [
        CommonModule,
        CardItemModule,
        PUtilsLibModule,
        SwipeDirectiveModule,
    ],
  exports: [
    ShowcaseRecommendationComponent,
    ShowcaseStoryCarouselComponent,
    ShowcaseStoryMobileCarouselComponent,
    ShowcaseStoryMobileComponent,
    ShowcaseStoryComponent,
    ArrowButtonComponent,
    HomeContentShowcaseComponent,
    BannerScrollCarouselComponent,
    BannerNoscrollComponent,
    ContentBannerComponent,
    ShowcaseRecommendationGridComponent,
    CardBannerNoscrollComponent,
    CardBannerScrollComponent,
    ShowcaseRecommendationDirective,
  ],
})
export class SharedShowcaseModule { }
