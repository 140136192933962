import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BannersService } from 'src/app/services/banners/banners.service';
import { getBanners, updateBanners } from '../actions/banner.actions';

@Injectable()
export class BannersEffects {

  getBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBanners),
      mergeMap(({ params }) => this._bannersService.getMainBanner(params).pipe(
        map((response) => updateBanners({ banners: response?.content })),
        catchError((error) => of(error)),
      )),
    ),
  );

  constructor(private actions$: Actions, private _bannersService: BannersService) { }
}