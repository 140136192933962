import {Component, Input} from '@angular/core';
import * as tagManagerActions from '../../../store/actions/tagmanager.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/state/app.state';
import { Story } from 'src/app/services/home/story-model';

@Component({
  selector: 'app-showcase-story',
  templateUrl: './showcase-story.component.html',
  styleUrls: ['./showcase-story.component.scss']
})
export class ShowcaseStoryComponent {
  @Input() story: Story;
  @Input() size: 'small' | 'large';

  constructor(private store?: Store<AppState>) {
    this.story = {} as Story;
  }

  tagManager(): void {
    this.store.dispatch(tagManagerActions.pushBannerNameDepartamentsTagManager({ banner: this.story }));
  }
}
