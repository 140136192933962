<div [ngClass]="size" class="p-brand">
    <a 
      class="border"
      [href]="story.link"
      (click)="tagManager()"
    >
      <div class="circle">
        <div class="img-container">
          <img [alt]="story.description ? story.description : 'Panvel marcas'" [src]="story.image">
        </div>
      </div>
    </a>
  <h3 class="description">{{ story.description }}</h3>
</div>
