import {createReducer, on} from '@ngrx/store';
import * as TagmanagerAction from '../actions/tagmanager.actions';

export const featureKey = 'tagManager';

export interface TagManagerState {
  push: boolean;
}

export const initialState: TagManagerState = {
  push: false
};
export const reducer = createReducer<TagManagerState>(
  initialState,
  on(TagmanagerAction.pushItemToCart, (): TagManagerState => ({push: true}))
);
