import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statics-skeleton',
  templateUrl: './statics-skeleton.component.html',
  styleUrls: ['./statics-skeleton.component.scss'],
})
export class StaticsSkeletonComponent {
  backgroundColor = environment.theme === 'DARK' ? '#31363F' : '#EBE8E8C2'
}
