import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as showCaseReducer from '../reducers/show-case.reducer';

export const getShowCaseFeatureState = createFeatureSelector<showCaseReducer.ShowCaseState>(
  showCaseReducer.ShowCaseFeatureKey
);

export const getShowCase = createSelector(
  getShowCaseFeatureState,
  state => state?.showcases
);

export const getShowCaseCustomer = createSelector(
  getShowCaseFeatureState,
  state => state?.showcasesCustomer
);

export const getShowCaseCustom = createSelector(
  getShowCaseFeatureState,
  state => state?.showcasesCustom
);
