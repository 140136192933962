export const environment = {
  production: true,
  apiUrl: 'http://panvel-ecommerce-bff.default.svc.cluster.local:9000/panvel-ecommerce-bff',
  appToken: 'ZYkPuDaVJEiD',
  landingPageUrl:
    'https://cdn1.staticpanvel.com.br/cdn_service/landpages/home/barra-mosaico/index-reescrita.html',
  landingTwoPageUrl:
    'https://cdn1.staticpanvel.com.br/cdn_service/landpages/home/mosaico-rodape/index-reescrita.html',
  baseHref: '/',
  panvelHome: '/',
  panvelCart: '/panvel/checkout/cesta',
  domain: 'panvel.com',
  panvelLogin: '/panvel/login/entrar',
  cookieCartName: 'shoppingCartId',
  theme: 'LIGHT',
  origin: 'BLACK_FRIDAY',
  secretKey:'AIzaSyBk4yFgGfqnoY4acDP3k11fkeWdeUBBJe0',
  siteKey:'6Ld-yEQdAAAAAJsCM5fSe3VgSEucoqNBrKhLv__Z',
};
