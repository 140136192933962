import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as cartReducer from '../reducers/cart.reducer';

export const getProductFeatureState = createFeatureSelector<cartReducer.CartState>(
  cartReducer.cartFeatureKey
);

export const getCart = createSelector(
  getProductFeatureState,
  state => state?.cart
);
