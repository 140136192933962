import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, catchError, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { ShowcaseChaordic } from "./home/showcase.chaordic-model";
import { EventModel } from "./shopping-cart/model/request/event";
import { AffiliateEvents } from "./home/affiliate.model";

@Injectable({ providedIn: "root" })
export class EventChaordicService {
  constructor(private http: HttpClient) { }

  private options(): {} {
    return {
      "user-id": "1",
      "client-ip": "123",
      page: "HOME",
    };
  }

  public getShowCaseChaordic(): Observable<ShowcaseChaordic[]> {
    return this.http
      .get<ShowcaseChaordic[]>(
        environment.baseHref + "api/v2/recommendation/chaordic",
        { headers: this.options() }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }


  sendShowCaseClick(trackingId: string, panvelCode: number): Observable<void> {
    return this.http.post<void>(`${environment.baseHref}api/v1/event/item/${panvelCode}`, { trackingId: trackingId },
      { params: new HttpParams().set("interactionType", "ADD_TO_CART") });
  }

  public sendShowCaseView(
    impressionUrl: string
  ): Observable<ShowcaseChaordic[]> {
    return this.http.get<ShowcaseChaordic[]>(
      environment.baseHref +
      "api/v1/event/impression?trackingImpression=" +
      impressionUrl
    );
  }
  public sendEvent(body: EventModel): Observable<void> {
    return this.http.post<void>(environment.baseHref + 'api/v1/event/cart', body);
  }

  public sendEventView(): Observable<void> {
    return this.http.post<void>(environment.baseHref + 'api/v1/event/home', {}).pipe(catchError(() => EMPTY));
  }

  public postAffiliateEvent(affiliateEvents: AffiliateEvents) {
    return this.http.post(`${environment.baseHref}api/v3/affiliate-marketing/event`, affiliateEvents)
  }
}
