import {Directive, Input} from '@angular/core';
import { ShowcaseStory } from 'src/app/services/home/showcase-story-model';
import { Story } from 'src/app/services/home/story-model';

@Directive({
  selector: '[appShowcaseBrandBase]'
})
export class ShowcaseStoryBaseDirective {
  @Input() showcaseStory: ShowcaseStory;
  @Input() size: 'small' | 'large';
  storys: Story[];
  storyList: Story[];

  constructor() {
    this.showcaseStory = {} as ShowcaseStory;
  }

  prev(): void {
    this.storyList.unshift(this.storyList.pop());
  }

  next(): void {
    this.storyList.push(this.storyList.shift());
  }
}
