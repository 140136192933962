<section>
  <div class="shipping-bar" *ngIf="shippingBar">
    <div class="shipping-bar-title">
      <span class="icon-truck"></span>
      <div>
        <span [innerHTML]="shippingBar.description" class="topbar-description"></span>
        <span class="topbar-subdescription" [innerHTML]="shippingBar.sortDescription"></span>
      </div>
    </div>
    <button>
      <a [href]="shippingBar.buttonLink" [innerHTML]="shippingBar.buttonDescription" aria-label="shipping-bar">
      </a>
    </button>
  </div>
</section>
