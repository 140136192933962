import { isPlatformBrowser } from "@angular/common";
import {
  ViewChild,
  ViewContainerRef,
  HostListener,
  Input,
  Component,
  PLATFORM_ID,
  Inject,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie";
import { Showcase } from "src/app/services/home/showcase-model";
import {
  loadRecomendationCustom,
  loadRecomendationCustomer,
  loadShowCase,
  loadShowCaseSuccess
} from "src/app/store/actions/show-case.actions";
import { getShowCase } from "src/app/store/selectors/show-case.selectors";
import { AppState } from "src/app/store/state/app.state";

@Component({
  selector: 'app-dynamic-loading',
  template: `
    <ng-template #dynamicContent></ng-template>
    <app-showcase-skeleton *ngIf="showcase || !showcases"></app-showcase-skeleton>`,
})
export class DynamicLoadingComponent implements OnDestroy {
  @Input() public theme: string;
  public showcase: Showcase;
  public showcases: Showcase[];
  public componentCounter = 0;
  public firstCall = true;
  @ViewChild("dynamicContent", { read: ViewContainerRef })
  private dynamicContainer: ViewContainerRef;
  @HostListener("window:scroll", []) onWindowScroll() {
    const pageHeightTotal = document.querySelector('body').scrollHeight
    const pageHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
    const pageScrollPorcentage =
      this.getPageScrollYPorcentage(pageHeight, pageHeightTotal);
    const limitScreenPercent =
      document.documentElement.clientWidth < 1024 ? [2, 25] : [5, 40];
    if (!this.showcases && pageScrollPorcentage > limitScreenPercent[0]) {
      if (this.firstCall) this.getAllShowCases();
    } else if (this.showcases && pageScrollPorcentage > limitScreenPercent[1]) {
      this.loadShowCaseDynamic(1);
    }
  }
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.store.select(getShowCase).subscribe(date => {
      this.showcases = date;
      if (this.showcases) {
        this.getShowCase();
        this.loadShowCaseDynamic(3);
      }
    });
  }

  ngOnDestroy(): void {
    this.dynamicContainer.clear();
    this.store.dispatch(loadShowCaseSuccess({ data: null }))
  }

  private getPageScrollYPorcentage(height: number, totalHeitht: number): number {
    return height * 100 / totalHeitht;
  }

  private getShowCase() {
    this.showcase = this.showcases[this.componentCounter];
  }

  async loadShowCaseDynamic(numberShowcase: number) {
    for (let i = 0; i <= numberShowcase; i++) {
      if (!!this.showcase) {
        const { HomeContentShowcaseComponent } = await import("../home-content-showcase/home-content-showcase.component");
        const { instance } = this.dynamicContainer?.createComponent(HomeContentShowcaseComponent);
        instance.showcase = this.showcase;
        instance.theme = this.theme;
        this.componentCounter++;
        this.getShowCase();
      }
    }
  }

  getAllShowCases() {
    if (this.isSpa()) {
      this.route.data.subscribe((data: { item: any }) => {
        const uf = this.cookieService.get('UF') || 'RS';
        const convenance = data.item?.cart?.convenance?.find(
          (conv) => conv?.active === true)?.code;
        if (data.item?.cart?.customer?.cpf) {
          this.store.dispatch(loadRecomendationCustomer({ convenance, uf }));
          this.store.dispatch(loadRecomendationCustom({ convenance, uf }))
        }
        this.firstCall = false;
        this.store.dispatch(loadShowCase({ convenance, uf }));
      });
    }
  }

  isSpa(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
