import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  sendWheelFailed,
  sendWheelForm,
  sendWheelFormSuccess,
} from "../actions/wheel.actions";
import { WheelServiceCadaster } from "../../services/wheel/cadaster.wheel.service";

@Injectable()
export class WheelEffect {
  sendWheelForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendWheelForm),
      mergeMap((body) =>
        this.wheelService.send(body.form).pipe(
          map(() => sendWheelFormSuccess()),
          catchError(() => of(sendWheelFailed()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private wheelService: WheelServiceCadaster
  ) {}
}
