import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  showCookiesAccept: boolean;

  constructor(private cookieService: CookieService, @Inject(PLATFORM_ID) private platformId) {
    this.showCookiesAccept = false;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showCookiesAccept = !this.cookieService.get('cookie');
    }
  }

  acceptCookie() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this.cookieService.set('cookie', 'true', {
      path: '/',
      domain: environment.domain,
      expires: date
    });
    this.showCookiesAccept = false;
  }

}
