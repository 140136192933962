import { createAction, props } from '@ngrx/store';
import { Banner } from 'src/app/services/home/banner-model';
import { MainBanner } from 'src/app/services/home/main-banner-model';
import { Story } from 'src/app/services/home/story-model';
import { Item } from '../../services/shopping-cart/model/response/shopping-cart-response.model';
import { Customer } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';

export const pushItemToCart = createAction(
  '[pushItem] Push item  to tagmanager',
  props<{ item: Item }>()
);

export const pushBannerMenuTagManager = createAction(
  '[pushItem] Push banner menu  to tagmanager',
  props<{ banner: Banner }>()
);

export const pushBannerNameTagManager = createAction(
  '[pushItem] Push banner name  to tagmanager',
  props<{ banner: Banner }>()
);

export const pushBannerNameDepartamentsTagManager = createAction(
  '[pushItem] Push banner nameDepartaments  to tagmanager',
  props<{ banner: Story }>()
);

export const pushMainBannerTagManager = createAction(
  '[pushMainBannerTagManager] Push banner principal mobile to tagmanager',
  props<{ banner: MainBanner | Banner }>()
);

export const pushUserData = createAction(
  '[setUserData] Set user data',
  props<{ customer: Customer }>()
);

export const pushBannerView = createAction(
  '[push Banner View] Push Banner View',
  props<{ banner: Banner | Story }>()
);