import { WheelRequest } from "./../../services/home/wheel-request.model";
import { createAction, props } from "@ngrx/store";

export const sendWheelForm = createAction(
  "[ Wheel ] Send wheel",
  props<{ form: WheelRequest }>()
);

export const sendWheelFormSuccess = createAction(
  "[ Wheel ] Send wheel success"
);

export const sendWheelFailed = createAction("[ Wheel ] Send wheel failed");
