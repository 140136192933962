import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-showcase-skeleton',
  templateUrl: './showcase-skeleton.component.html',
  styleUrls: ['./showcase-skeleton.component.scss'],
})
export class ShowcaseSkeletonComponent {
  backgroundColor = environment.theme === 'DARK' ? '#31363F' : '#EBE8E8C2'
}
